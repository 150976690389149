import styled from "styled-components"
import colors from "@styles/colors"

const Table = styled.table`
  width: 100%;
  margin-bottom: 1.5rem;
  border-collapse: collapse;

  thead th {
    vertical-align: bottom;
    border-bottom: 3px solid ${colors.gray.normal};
  }

  th,
  td {
    text-align: inherit;
    padding: 0.75rem;
    vertical-align: middle;
    border-bottom: 1px solid ${colors.gray.normal};
  }
`

Table.propTypes = {}

export default Table
