import React from "react"
import { graphql } from "gatsby"
import Page from "@components/Layout/DocumentationPage"
import Paragraph from "../../../components/Paragraph"
import Table from "../../../components/Table"
import List from "../../../components/List"
import { DocumentationImage } from "../../../components/Image"
import Heading2 from "../../../components/Headings/Heading2"
import Heading3 from "../../../components/Headings/Heading3"

export const query = graphql`
  query {
    inviteByEmail: file(
      relativePath: { eq: "docs/membership-invite-by-email.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    inviteDialog: file(
      relativePath: { eq: "docs/membership-invite-dialog.png" }
    ) {
      childImageSharp {
        fixed(width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    acceptInvitation: file(
      relativePath: { eq: "docs/membership-accept-invitation.png" }
    ) {
      childImageSharp {
        fixed(width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default ({ data }) => (
  <Page title={"Organizations"}>
    <Heading2>Membership</Heading2>
    <Paragraph>
      Your personal account can be re-used across multiple organizations. Access
      within an organization is managed through roles. You can have different
      roles assigned per organization.
    </Paragraph>
    <Paragraph>The following roles are available:</Paragraph>
    <List>
      <li>Owner</li>
      <li>Member</li>
    </List>
    <Table>
      <thead>
        <tr>
          <th>Action</th>
          <th>Member</th>
          <th>Owner</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Can view projects and their changelogs</td>
          <td>X</td>
          <td>X</td>
        </tr>
        <tr>
          <td>Can create projects</td>
          <td>X</td>
          <td>X</td>
        </tr>
        <tr>
          <td>Can install or uninstall integrations</td>
          <td></td>
          <td>X</td>
        </tr>
        <tr>
          <td>Can invite or remove members</td>
          <td></td>
          <td>X</td>
        </tr>
        <tr>
          <td>Can manage organization settings</td>
          <td></td>
          <td>X</td>
        </tr>
        <tr>
          <td>Can remove an organization</td>
          <td></td>
          <td>X</td>
        </tr>
      </tbody>
    </Table>
    <Heading3>Inviting members into your organization</Heading3>
    <Paragraph>
      For someone to join your organization you will need to send them an
      invitation by e-mail. To do so go to the organizations Settings > Members
      > Invite by e-mail.
    </Paragraph>
    <DocumentationImage fluid={data.inviteByEmail.childImageSharp.fluid} />
    <Paragraph>
      This will open up a modal where you can enter the e-mail address of the
      person you wish to invite.
    </Paragraph>
    <DocumentationImage fixed={data.inviteDialog.childImageSharp.fixed} />
    <Paragraph>
      The person will receive an e-mail from us with a link to accept the
      invitation. If they do not have a ChangeHub account yet they will be asked
      to create one first.
    </Paragraph>
    <DocumentationImage fixed={data.acceptInvitation.childImageSharp.fixed} />
    <Heading2>Removing an organization</Heading2>
    <Paragraph>
      An organization owner can remove the entire organization and all
      associated data. It's important to note that this will <em>not</em> remove
      any personal accounts, but they will lose their membership to the
      organization.
    </Paragraph>
    <Paragraph>
      You can remove your organization by going to Settings > General Settings >
      Remove organization. We will prompt you for confirmation.
    </Paragraph>
  </Page>
)
